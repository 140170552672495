export const Close = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0"
  >
    <path
      d="M7.99992 1.3335C4.32659 1.3335 1.33325 4.32683 1.33325 8.00016C1.33325 11.6735 4.32659 14.6668 7.99992 14.6668C11.6733 14.6668 14.6666 11.6735 14.6666 8.00016C14.6666 4.32683 11.6733 1.3335 7.99992 1.3335ZM10.2399 9.5335C10.4333 9.72683 10.4333 10.0468 10.2399 10.2402C10.1399 10.3402 10.0133 10.3868 9.88658 10.3868C9.75992 10.3868 9.63325 10.3402 9.53325 10.2402L7.99992 8.70683L6.46658 10.2402C6.36658 10.3402 6.23992 10.3868 6.11325 10.3868C5.98659 10.3868 5.85992 10.3402 5.75992 10.2402C5.56659 10.0468 5.56659 9.72683 5.75992 9.5335L7.29325 8.00016L5.75992 6.46683C5.56659 6.2735 5.56659 5.9535 5.75992 5.76016C5.95325 5.56683 6.27325 5.56683 6.46658 5.76016L7.99992 7.2935L9.53325 5.76016C9.72658 5.56683 10.0466 5.56683 10.2399 5.76016C10.4333 5.9535 10.4333 6.2735 10.2399 6.46683L8.70658 8.00016L10.2399 9.5335Z"
      fill="white"
    />
  </svg>
);

export const CloseMenu = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x-close">
        <path
          id="Icon"
          d="M18 6L6 18M6 6L18 18"
          stroke="#ffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export const OpenMenu = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="menu-01">
        <path
          id="Icon"
          d="M3 12H21M3 6H21M3 18H21"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
