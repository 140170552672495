import React, { useEffect, useState } from "react";
import { MdOutlineTimerOff } from "react-icons/md";

const QuizTimer = ({ quizTimeLeft, setQuizTimeLeft, onComplete }) => {
  const [showtimer, setShowTimer] = useState(true);
  useEffect(() => {
    if (quizTimeLeft > 0) {
      const interval = setInterval(() => {
        setQuizTimeLeft((prevTimeLeft) => {
          const updatedTimeLeft = prevTimeLeft - 1;
          if (updatedTimeLeft <= 0) {
            clearInterval(interval);
            onComplete();
          }
          return Math.max(updatedTimeLeft, 0);
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [quizTimeLeft, setQuizTimeLeft]);

  const formatTime = () => {
    const minutes = Math.floor(quizTimeLeft / 60);
    const seconds = quizTimeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-2xl font-bold">
        {showtimer ? formatTime() : <MdOutlineTimerOff />}
      </div>
      <button
        onClick={() => setShowTimer((showtimer) => !showtimer)}
        className="border text-black border-solid rounded-xl px-6 py-1 mt-2  font-bold"
      >
        {showtimer ? "Hide" : "Show"}
      </button>
    </div>
  );
};

export default QuizTimer;
