import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "./card.scss";
import axios from "axios";
import { ensureArray } from "../../utils/ensureArray";
import LoadingSkeleton from "../LoadingSkelton/LoadingSkelton";
import { useCart } from "react-use-cart";
import CardItems from "./CartItems";
import { SlBasket } from "react-icons/sl";

const Cards = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { addItem, removeItem, totalUniqueItems } = useCart();

  // const handleButtonClick = (index, data) => {
  //   if (!tests[index].disabled) {
  //     addItem(data);
  //     setTests((prevTests) =>
  //       prevTests.map((test, i) =>
  //         i === index ? { ...test, disabled: true } : test
  //       )
  //     );
  //   }
  // };
  // const handleRemoveFromCart = (itemId) => {
  //   const updatedTests = tests.map((test) => {
  //     if (test.id === itemId) {
  //       removeItem(itemId);
  //       return { ...test, disabled: false };
  //     }
  //     return test;
  //   });

  //   setTests(updatedTests);
  // };

  const handleButtonClick = (index, data) => {
    const productKey = `product_added_${data.id}`;
    const isProductAdded = localStorage.getItem(productKey);

    if (!tests[index].disabled && !isProductAdded) {
      addItem(data);
      localStorage.setItem(productKey, "true");
      setTests((prevTests) =>
        prevTests.map((test, i) =>
          i === index ? { ...test, disabled: true } : test
        )
      );
    }
  };
  const handleRemoveFromCart = (itemId) => {
    const updatedTests = tests.map((test) => {
      if (test.id === itemId) {
        removeItem(itemId);
        localStorage.removeItem(`product_added_${itemId}`);
        return { ...test, disabled: false };
      }
      return test;
    });

    setTests(updatedTests);
  };
  // useEffect(() => {
  //   const initialTests = tests.map((test) => {
  //     const isProductAdded = localStorage.getItem(`product_added_${test.id}`);
  //     return {
  //       ...test,
  //       disabled: !!isProductAdded,
  //     };
  //   });

  //   setTests(initialTests);
  // }, []);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axios
      .get("https://panel.digitalsatexam.az/api/exam/all", {
        signal: controller.signal,
      })
      .then((res) => {
        const fetchedTests = res.data.map((test) => {
          const isProductAdded = localStorage.getItem(
            `product_added_${test.id}`
          );
          return {
            ...test,
            disabled: !!isProductAdded,
          };
        });
        setTests(fetchedTests);
        setLoading(false);
      })
      .catch(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, []);

  if (loading) return <LoadingSkeleton />;

  return (
    <>
      <div className="container my-3">
        <button
          onClick={() => setVisible(true)}
          className="my-10 flex items-center gap-2 px-4 py-2 bg-slate-800 rounded-md text-white"
        >
          View Cart
          <SlBasket />
          <span className="bg-red-500 text-white rounded-full  w-6 h-6 flex items-center justify-center">
            {totalUniqueItems}
          </span>
        </button>
        <div className="row ">
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            className="mySwiper"
          >
            {ensureArray(tests) &&
              Object.keys(tests).length > 0 &&
              tests.map((data, index) => (
                <SwiperSlide
                  key={data.id}
                  className="d-flex align-items-stretch py-2"
                >
                  <div className="card w-full">
                    <div className="card-body">
                      <div className="image">
                        <img
                          alt={data?.content}
                          className="w-full   object-cover"
                          src={
                            data?.image ||
                            "https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8dGVzdHxlbnwwfHwwfHx8MA%3D%3D"
                          }
                        />
                      </div>
                      <h1 className="text-bold text-gray-800 text-2xl py-2">
                        {data?.price}₼
                      </h1>
                      <p
                        className="text-slate-700 my-2 text-sm 
                        line-clamp-4
                        "
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                      ></p>

                      <button
                        onClick={() => {
                          handleButtonClick(index, data);
                          setVisible(true);
                        }}
                        className={`rounded-md p-2 text-white  hover:bg-slate-800 bg-slate-700  disabled:bg-slate-400 disabled:cursor-not-allowed`}
                        disabled={data.disabled}
                      >
                        add to cart
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <CardItems
          visible={visible}
          setVisible={setVisible}
          removeItems={handleRemoveFromCart}
        />
      </div>
    </>
  );
};

export default Cards;
