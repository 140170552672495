import axios from "axios";
import toastMiddleware from "../middleware/ToastMidleware";
// const token = localStorage.getItem("token");

const ApiHelper = axios.create({
  baseURL: "https://panel.digitalsatexam.az/api/",
});

const requestConfig = ({ url, method, data }) => {
  return {
    url,
    method,
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

const AxiosOptionsMethodFunction = (method) => {
  axios.options("https://panel.digitalsatexam.az/api/exam/view/15");
};

AxiosOptionsMethodFunction("options");

export const GetApiData = async (url) => {
  try {
    const { data } = await ApiHelper.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postRegisterApi = async (url, { data }) => {
  try {
    const response = await ApiHelper.post(url, data, {});
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const postLoginApi = (url, data) => {
  try {
    const response = ApiHelper.post(url, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const postLogoutApi = (url) => {
  try {
    const response = ApiHelper.get(url);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const Logout = (url) => {
  try {
    const response = ApiHelper.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleReport = ({ data }) => {
  try {
    const response = ApiHelper.post(
      "https://panel.digitalsatexam.az/api/exam/question-report",
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    toastMiddleware.success("Thank you for your feedback!");
    return response;
  } catch (error) {
    toastMiddleware.error(error?.response?.data?.message);
  }
};
export const getResultAPi = async () => {
  return await ApiHelper.post(
    "https://panel.digitalsatexam.az/api/exam/user-exam/review",
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const getExamResultDetail = async (data) => {
  try {
    return await ApiHelper.post(
      "https://panel.digitalsatexam.az/api/exam/user-exam/review-detail",
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {}
};

// const requestConfig=({url,methode,data})=>{
//   return {
//     url,
//     method:methode,
//     data,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   }
// }

ApiHelper.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
