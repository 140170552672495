import { IoMdInformationCircleOutline } from "react-icons/io";
import { CheckStatus } from "../../utils/helper";

const ResultTable = ({ data, HandleOpenModal }) => {
  return (
    <div className="overflow-x-auto relative my-5">
      <table className="w-full text-sm text-left text-slate-500">
        <thead className="text-xs text-slate-700 uppercase bg-gray-100  ">
          <tr>
            <th scope="col" className="py-3 px-6">
              Question
            </th>
            <th scope="col" className="py-3 px-6">
              Section
            </th>
            <th scope="col" className="py-3 px-6">
              Correct Answer
            </th>
            <th scope="col" className="py-3 px-6">
              Your Answer
            </th>
            <th scope="col" className="py-3 px-6">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr
                className="bg-white border-1 dark:bg-gray-800 dark:border-gray-700"
                key={`item_${index}`}
              >
                <th
                  scope="row"
                  className="py-2 px-6 font-medium text-gray-900 whitespace border-1"
                >
                  {++index}
                </th>
                <td className="py-2 px-6 border-1">{item?.module}</td>
                <td className={`py-2 px-6 border-1 `}>
                  {item?.correct_answer?.title}
                </td>
                <td
                  className={`py-2 px-6 border-1   ${CheckStatus(
                    item?.status
                  )}`}
                >
                  {item.answer === "empty-204" ? "OMITTED" : item?.answer}
                </td>
                <td className="py-2 px-6 bottom-1">
                  <button
                    onClick={() => HandleOpenModal(item)}
                    className="font-semibold  flex gap-2 items-center justify-center
                transition duration-500 ease-in-out bg-slate-700 text-white hover:bg-slate-600 
                  hover:text-white rounded-3xl  w-full border-1 hover:border-2 py-2.5"
                  >
                    Review
                    <IoMdInformationCircleOutline size={24} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultTable;
