import React, { useState } from "react";
import { MdOutlineFeedback } from "react-icons/md";
import ModalContext from "../ModalContext/ModalContext";

const FeedbackQuestions = ({ onSubmited }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");

  const HandleChange = (e) => {
    setFeedback(e.target.value);
  };

  return (
    <React.Fragment>
      {" "}
      <button
        onClick={() => {
          setOpenFeedback(true);
        }}
        className="flex items-center gap-2 bg-slate-700 rounded-md w-[200px] py-2 px-2 text-white"
      >
        <MdOutlineFeedback />
        <span className=" text-sm">Report a problem!</span>
      </button>
      <ModalContext
        maxWidth={"650px"}
        onRequestClose={() => {
          setOpenFeedback(false);
        }}
        modalIsOpen={openFeedback}
        iscollapse={false}
      >
        <div className="p-2 md:p-5 max-w-[600px] w-[600px] max-md:w-auto">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900">Feedback</h2>
            <button
              className="text-gray-700 "
              aria-label="Close"
              onClick={() => {
                setOpenFeedback(false);
              }}
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path d="M18.364 5.636a.999.999 0 010 1.414L7.414 18.364a.999.999 0 11-1.414-1.414L16.95 5.636a.999.999 0 011.414 0z" />
                <path d="M18.364 18.364a.999.999 0 01-1.414 0L5.636 7.414a.999.999 0 111.414-1.414L18.364 16.95a.999.999 0 010 1.414z" />
              </svg>
            </button>
          </div>

          <form className="space-y-4" action="#">
            <div>
              <label
                htmlFor="text"
                className="block mb-2 my-2 text-sm font-medium text-gray-900"
              >
                Your feedback
              </label>
              <textarea
                onChange={HandleChange}
                value={feedback}
                name="report"
                id="feedback"
                rows="4"
                className="bg-gray-50 border resize-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Your feedback"
                required
              ></textarea>
            </div>

            <button
              onClick={() => {
                onSubmited(feedback);
                setOpenFeedback(false);
                setFeedback("");
              }}
              disabled={feedback.length < 2}
              type="submit"
              className="w-full
              disabled:cursor-not-allowed disabled:opacity-50
               text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Send feedback
            </button>
          </form>
        </div>
      </ModalContext>
    </React.Fragment>
  );
};

export default FeedbackQuestions;
