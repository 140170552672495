import React, { useState, useEffect, useRef } from "react";
import { OptionList } from "./OptionList";
import Modal from "react-modal";
import axios from "axios";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import References from "../References/References";
import FeedbackQuestions from "../FeedbackQuestions/FeedbackQuestions";
import Calculate from "../Calculate/Calculate";
import { GoStrikethrough } from "react-icons/go";
import QuestionNavigate from "../QuestionNavigate/QuestionNavigate";
import BookmarkToggle from "../AddBokMark/AddBookMark";
import { IoBookmark } from "react-icons/io5";
import MathSection from "../MatchSection/MatchSection";
import BreakTimer from "../BreakTimer/BreakTimer";
import QuizTimer from "../QuizTimer/QuizTimer";
import LoadingModule from "../LoadingModule/LoadingModule";
import { handleReport } from "../../utils/ApiHelper";
import { capitalizeFirstLetterOfEachWord } from "../../utils/helper";
import { toast } from "sonner";
import { ThreeDots } from "react-loader-spinner";
import ExitDialog from "../Dialog/ExitDialog";
import Highlighter from "web-highlighter";

export const Quiz = () => {
  const params = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [loadingModule, setLoadingModule] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizTimeLeft, setQuizTimeLeft] = useState(1920);
  const [selectedModule, setSelectedModule] = useState(() => {
    const savedModule = localStorage.getItem("selectedModule");
    return savedModule ? JSON.parse(savedModule) : null;
  });
  const [loading, setLoading] = useState(false);
  const [answerText, setAnswerText] = useState("");
  const [strikethrough, setStrikethrough] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [openMath, setOpenMath] = useState(false);
  const [showBreakTimer, setShowBreakTimer] = useState(false);
  const token = localStorage.getItem("token");
  const [timeLeft, setTimeLeft] = useState(600);

  const [isAnnotationActive, setIsAnnotationActive] = useState(false);
  const [isVisibleDialog, setVisibleDialog] = useState(false);
  const [blocker, setBlocker] = useState(true);
  const [content, setContent] = useState("");

  useEffect(() => {
    const question = selectedModule?.questions[activeQuestion];
    if (question) {
      setContent(question.title);
    }
  }, [selectedModule, activeQuestion]);

  let blockers = useBlocker(({ currentLocation, nextLocation }) => blocker);

  useEffect(() => {
    if (blockers.state === "blocked") {
      setVisibleDialog(true);
    }
  }, [blockers.state]);

  useEffect(() => {
    axios
      .get(`https://panel.digitalsatexam.az/api/exam/view/${params?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setQuizQuestions(res?.data?.sections);
        }
      })
      .catch(() => {
        throw new Error("Something went wrong");
      });
  }, [params?.id]);

  const checkIfOpenQuestion = (questionIndex) => {
    const currentQuestion = selectedModule.questions[questionIndex];
    return currentQuestion.type === "Açıq";
  };

  const handleNextQuestion = () => {
    setIsAnnotationActive(false);
    const isCurrentQuestionOpen = checkIfOpenQuestion(activeQuestion);
    if (isCurrentQuestionOpen) {
      handleSelectAnswer(answerText);
    }
    const nextQuestionIndex = activeQuestion + 1;
    if (nextQuestionIndex < selectedModule.questions.length) {
      setActiveQuestion(nextQuestionIndex);
      loadAnswerTextForQuestion(selectedModule.questions[nextQuestionIndex].id);
    } else {
      openModal();
    }
  };

  const handlePrevQuestion = () => {
    const isCurrentQuestionOpen = checkIfOpenQuestion(activeQuestion);
    if (isCurrentQuestionOpen) {
      handleSelectAnswer(answerText);
    }
    const prevQuestionIndex = activeQuestion - 1;
    if (prevQuestionIndex >= 0) {
      setActiveQuestion(prevQuestionIndex);
      loadAnswerTextForQuestion(selectedModule.questions[prevQuestionIndex].id);
    }
  };

  const handleSelectAnswer = () => {
    const selectQuestionId = selectedModule?.questions[activeQuestion]?.id;
    const updatedAnswer = {
      answer: answerText,
      question_id: selectQuestionId,
    };

    setAnsweredQuestions((prev) => {
      const updatedAnswers = prev.filter(
        (item) => item.question_id !== selectQuestionId
      );

      if (answerText !== "") {
        updatedAnswers.push(updatedAnswer);
      }
      return updatedAnswers;
    });
  };

  const loadAnswerTextForQuestion = (questionId) => {
    const foundAnswer = answeredQuestions.find(
      (q) => q.question_id === questionId
    );
    setAnswerText(foundAnswer ? foundAnswer.answer : "");
  };
  useEffect(() => {
    if (selectedModule && selectedModule.questions[activeQuestion]) {
      loadAnswerTextForQuestion(selectedModule.questions[activeQuestion].id);
    }
  }, [activeQuestion, selectedModule, answeredQuestions]);

  const HandleVariantSelect = (title) => {
    const selectQuestionId = selectedModule.questions[activeQuestion].id;

    if (title) {
      const isVariantSelected = answeredQuestions.some(
        (item) => item.question_id === selectQuestionId && item.answer === title
      );

      if (isVariantSelected) {
        setAnsweredQuestions((prev) =>
          prev.filter(
            (item) =>
              item.question_id !== selectQuestionId || item.answer !== title
          )
        );
      } else {
        const PostQuestionItem = {
          answer: title,
          question_id: selectQuestionId,
        };
        setAnsweredQuestions((prev) => [
          ...prev.filter((item) => item.question_id !== selectQuestionId),
          PostQuestionItem,
        ]);
      }
    } else {
      setAnsweredQuestions((prev) => {
        const filteredQuestions = prev.filter(
          (item) => item.question_id !== selectQuestionId
        );
        const EmptyQuestionItem = {
          answer: "empty-204",
          question_id: selectQuestionId,
        };
        return [...filteredQuestions, EmptyQuestionItem];
      });
    }
  };

  const numberOfQuestions = selectedModule?.question_count;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
  };
  Modal.setAppElement("#root");

  const openModal = () => {
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const HandleFinishByHistory = () => {
    const Payload = {
      exam_id: params?.id,
      section_id: selectedModule?.id,
      questions: selectedModule.questions.map((item) => {
        const foundAnswer = answeredQuestions.find(
          (answer) => answer.question_id === item.id
        );
        return {
          question_id: item.id,
          answer: foundAnswer?.answer || "empty-204",
        };
      }),
    };

    axios
      .post(
        "https://panel.digitalsatexam.az/api/exam/last-final",
        {
          exam_id: params?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setBlocker(false);

          navigate("/result");
        }
      });
    axios
      .post("https://panel.digitalsatexam.az/api/exam/final", Payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Successful!`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleSubmitQuiz = () => {
    setLoadingModule(true);
    const Payload = {
      exam_id: params?.id,
      section_id: selectedModule?.id,
      questions: selectedModule.questions.map((item) => {
        const foundAnswer = answeredQuestions.find(
          (answer) => answer.question_id === item.id
        );
        return {
          question_id: item.id,
          answer: foundAnswer?.answer || "empty-204",
        };
      }),
    };
    axios
      .post("https://panel.digitalsatexam.az/api/exam/final", Payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`Successfull. Please, wait!`);
          setBlocker(false);
          setTimeout(() => {
            setLoadingModule(false);
            moveToNextModule();
          }, 500);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const HandleReport = (feedback) => {
    const Payload = {
      report: feedback,
      question_id: selectedModule?.questions[activeQuestion]?.id,
    };
    handleReport({
      data: Payload,
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleToggleBookmark = (questionId) => {
    setBookmarks((prevBookmarks) => ({
      ...prevBookmarks,
      [questionId]: !prevBookmarks[questionId],
    }));
  };

  useEffect(() => {
    if (quizTimeLeft === 0) {
      HandleSubmitQuiz();
    }
  }, [quizTimeLeft]);
  useEffect(() => {
    if (timeLeft === 0) {
      setShowBreakTimer(false);
    }
  }, [timeLeft]);

  const handleQuizComplete = () => {
    setQuizFinished(true);
  };

  useEffect(() => {
    if (selectedModule) {
      localStorage.setItem("selectedModule", JSON.stringify(selectedModule));
    }
  }, [selectedModule]);

  useEffect(() => {
    if (!selectedModule && quizQuestions?.length > 0) {
      const savedModuleId = localStorage.getItem("selectedModule");
      const savedModule = savedModuleId ? JSON.parse(savedModuleId) : null;

      const moduleToSelect = savedModule
        ? quizQuestions.find((module) => module.id === savedModule.id)
        : quizQuestions[0];

      setSelectedModule(moduleToSelect);
    }
  }, [quizQuestions, selectedModule]);

  useEffect(() => {
    const annotatableSection = document.querySelector("#annotatable-section");
    if (!annotatableSection) {
      return;
    }

    const highlighter = new Highlighter({
      // Ensure you're targeting the correct element
      $root: annotatableSection,
    });

    if (isAnnotationActive) {
      highlighter.run();
    } else {
      highlighter.dispose();
    }

    return () => {
      highlighter.dispose();
    };
  }, [isAnnotationActive]);
  useEffect(() => {
    const didReload = localStorage.getItem("reload") === "true";
    if (didReload) {
      localStorage.setItem("reload", "false");
    }
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      if (window.confirm("Are you sure you want to leave this page?")) {
        localStorage.setItem("reload", "true");
        return true;
      } else {
        return false;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const typesetMathContent = () => {
      if (window.MathJax) {
        window.MathJax.typesetClear();
        window.MathJax.typesetPromise()
          .then(() => {})
          .catch((err) => {
            console.error("MathJax typesetting failed: ", err);
          });
      }
    };

    const timer = setTimeout(() => {
      typesetMathContent();
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [content, selectedModule, activeQuestion, answeredQuestions]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <h1 className="text-3xl font-bold text-black">Loading...</h1>
      </div>
    );
  }

  const moveToNextModule = () => {
    setQuizTimeLeft(1920);
    setAnsweredQuestions([]);
    const nextModuleIndex =
      quizQuestions.findIndex((m) => m.id === selectedModule.id) + 1;
    if (nextModuleIndex < quizQuestions.length) {
      const nextModule = quizQuestions[nextModuleIndex];
      if (nextModuleIndex === 2) {
        setShowBreakTimer(true);
      }
      if (nextModuleIndex === 3 || nextModuleIndex === 2) {
        setQuizTimeLeft(2100);
      }

      setSelectedModule(nextModule);
      setActiveQuestion(0);
    } else {
      axios
        .post(
          "https://panel.digitalsatexam.az/api/exam/last-final",
          {
            exam_id: params?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setBlocker(false);
            localStorage.removeItem("selectedModule");

            navigate("/result");
          }
        });
    }
  };

  const onBreakTimerComplete = () => {
    setShowBreakTimer(false);
  };
  const UserData = JSON.parse(localStorage.getItem("user-verify"));

  return (
    <div className="container-fluid px-0">
      <ExitDialog
        visible={isVisibleDialog}
        onClose={() => setVisibleDialog(false)}
        onOk={() => {
          setBlocker(false);
          setVisibleDialog(false);
          HandleFinishByHistory();
          localStorage.removeItem("selectedModule");
        }}
      />
      {showBreakTimer ? (
        <BreakTimer
          setTimeLeft={setTimeLeft}
          timeLeft={timeLeft}
          onComplete={onBreakTimerComplete}
        />
      ) : (
        <div className="container-fluid px-4">
          {loadingModule ? (
            <LoadingModule module={`Module`} />
          ) : (
            <div className="row items-center justify-center">
              <div className="col-xl-12">
                {selectedModule?.questions ? (
                  <React.Fragment>
                    <div className="flex items-center justify-between py-3 max-md:flex-wrap gap-2">
                      <div>
                        {selectedModule?.title === "Module 1" ||
                        selectedModule?.title === "Module 2" ? (
                          <button
                            className="
                          bg-black text-white max-w-[120px] px-3 w-full h-[50px] flex justify-center items-center  rounded-md"
                          >
                            {selectedModule?.title}
                          </button>
                        ) : (
                          <MathSection
                            className="absolute top-20 z-10"
                            openMatch={openMath}
                            setOpenMathch={setOpenMath}
                            heading={selectedModule?.title}
                          />
                        )}
                      </div>
                      <div>
                        <QuizTimer
                          setQuizTimeLeft={setQuizTimeLeft}
                          quizTimeLeft={quizTimeLeft}
                          onComplete={handleQuizComplete}
                        />
                      </div>
                      <div className="flex gap-3 items-center justify-end ">
                        <Calculate />

                        <References />
                        {/* <FeedbackQuestions /> */}
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center gap-2"></div>

                      <div
                        ref={ref}
                        className="flex w-full  border-b-2 border-dashed border-black justify-between items-center font-medium text-sm bg-neutral-200 text-black px-4 py-3
             max-md:px-2 max-md:py-1 rounded-sm my-2
             max-md:flex-wrap max-md:gap-2
             "
                      >
                        <div className="flex gap-3 items-baseline">
                          <button className="bg-black text-white max-w-[50px] w-full h-[50px]  rounded-md">
                            {activeQuestion + 1} / {numberOfQuestions}
                          </button>
                          <BookmarkToggle
                            isBookmarked={
                              bookmarks[
                                selectedModule?.questions[activeQuestion]?.id
                              ]
                            }
                            onToggle={() =>
                              handleToggleBookmark(
                                selectedModule?.questions[activeQuestion]?.id
                              )
                            }
                          />
                        </div>
                        <div className="flex items-center gap-3">
                          <button
                            onClick={() => {
                              setIsAnnotationActive(!isAnnotationActive);
                              toast.success("Please select Higthlighted words");
                            }}
                            className="flex items-center gap-2 bg-slate-700 rounded-md py-2 px-2 text-white"
                          >
                            {isAnnotationActive ? "Deactivate" : "Annotation"}
                          </button>{" "}
                          <FeedbackQuestions
                            onSubmited={(feedback) => {
                              HandleReport(feedback);
                            }}
                          />
                          <button
                            onClick={() => {
                              setStrikethrough(!strikethrough);
                            }}
                            className="bg-white text-black flex items-center
           border-1  border-stone-950 
            justify-center max-w-[30px] w-full h-[30px] rounded-sm"
                          >
                            <GoStrikethrough size={20} />
                          </button>
                        </div>
                      </div>
                      <div className="mt-3 rounded-xl border border-brand-light-gray px-7 py-4 w-full h-full mb-1">
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                        >
                          {" "}
                          <div>
                            <h3>Answered</h3>
                            <div className="flex gap-2 flex-wrap max-w-lg">
                              {selectedModule?.questions.map(
                                (element, index) => (
                                  <button
                                    key={`button-${index}`}
                                    onClick={() => {
                                      setActiveQuestion(index - 1);
                                      setIsOpen(false);
                                    }}
                                    className={
                                      answeredQuestions.some(
                                        (item) =>
                                          item.question_id === element.id
                                      )
                                        ? "relative w-10 h-10 cursor-pointer bg-blue-700 text-white border-dotted border-2 border-gray-700 flex   items-center justify-center"
                                        : " relative w-10 h-10 cursor-pointer  border-dotted border-2 border-gray-700 flex text-black  items-center justify-center"
                                    }
                                  >
                                    {++index}
                                    {bookmarks[element.id] && (
                                      <div className="absolute top-[-4px] right-[-5px]">
                                        <IoBookmark color="red" size={18} />
                                      </div>
                                    )}
                                  </button>
                                )
                              )}
                            </div>
                            <div className="flex items-center gap-12 justify-between">
                              <button
                                className="btn btn-danger mt-4 text-white"
                                onClick={closeModal}
                              >
                                Close
                              </button>
                              <button
                                className="btn btn-success mt-4 text-white"
                                onClick={() => {
                                  HandleSubmitQuiz();
                                  closeModal();
                                }}
                              >
                                Finish
                              </button>
                            </div>
                          </div>
                        </Modal>

                        <div className="flex justify-between gap-12   max-md:flex-wrap ">
                          <div className="max-w-[800px] w-full h-[450px]  overflow-auto ">
                            <h3 className="text-lg font-semibold text-black">
                              Question {activeQuestion + 1}
                            </h3>

                            <div id="annotatable-section">
                              <div
                                id="math"
                                className="text-lg font-normal text-black options_variant"
                                dangerouslySetInnerHTML={{
                                  __html: content,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="w-full">
                            <OptionList
                              answeredQuestions={answeredQuestions}
                              activeQuestion={
                                selectedModule &&
                                selectedModule?.questions[activeQuestion]
                              }
                              options={
                                (selectedModule &&
                                  selectedModule?.questions[activeQuestion]
                                    ?.answers) ||
                                []
                              }
                              selectedAnswerIndex={selectedAnswerIndex}
                              onAnswerSelected={handleSelectAnswer}
                              onVariantSelected={HandleVariantSelect}
                              setAnswerText={setAnswerText}
                              answerText={answerText}
                              setStrikethrough={setStrikethrough}
                              strikethrough={strikethrough}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 w-full z-10 flex justify-between  items-center">
                        <div className="text-md text-black font-semibold">
                          {capitalizeFirstLetterOfEachWord(
                            UserData?.name_surname
                          )}
                        </div>
                        <div className="bg-slate-900 text-white px-8 py-2 flex justify-center items-center gap-2 rounded-md">
                          {" "}
                          {activeQuestion + 1} / {numberOfQuestions}
                          <QuestionNavigate
                            questions={selectedModule?.questions}
                            setActiveQuestion={setActiveQuestion}
                            bookmarks={bookmarks}
                            answeredQuestions={answeredQuestions}
                            isMath={
                              selectedModule?.title === "Module 3" ||
                              selectedModule?.title === "Module 4"
                            }
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            className="bg-blue-800 text-white py-3 px-1 w-[130px]   font-semibold text-sm rounded-xl"
                            onClick={handlePrevQuestion}
                          >
                            Prev
                          </button>

                          <button
                            className="bg-blue-800 text-white py-3 px-1 w-[130px]   font-semibold text-sm rounded-xl"
                            onClick={handleNextQuestion}
                          >
                            Next
                          </button>
                          {/* <button
                            className="bg-black rounded-md px-2 py-2 text-white"
                            onClick={moveToNextModule}
                          >
                            next Module
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="flex justify-center items-center w-screen h-screen">
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#1a2745"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
