import React, { useTransition } from "react";
import SpinnerLoading from "../../components/LaodingSpinner";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [mail, setMail] = React.useState("");
  const [pending, startTransation] = useTransition();
  const navigate = useNavigate();
  const HandleSubmit = () => {
    axios
      .post("https://panel.digitalsatexam.az/api/auth/forgot-password", {
        email: mail,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate("/password-verifcation");
            localStorage.setItem("email", mail);
          }, 2000);
        }
        if (res.status === 204) {
          toast.error(
            "Please, make sure that you have already created an account using this email address!"
          );
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <div className="max-w-[400px] w-full p-3">
        <h1 className="text-center text-slate-800 text-2xl my-2">
          Forget Password
        </h1>
        <div className="max-w-[500px] w-full ">
          <p className="text-center leading-6 text-slate-800 text-sm">
            Please enter your email address and we'll send you instructions on
            how to reset your password
          </p>
          <div className="flex justify-center items-center flex-wrap">
            <input
              name="mail"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              type="email"
              className="border focus:outline-none border-sleate-800  w-full rounded-md p-2 mt-2"
              placeholder="Enter your email"
            />

            <button
              onClick={() => {
                startTransation(() => {
                  HandleSubmit();
                });
              }}
              disabled={pending}
              className="bg-blue-800 w-full flex justify-center items-center text-white rounded-md p-2 mt-3"
            >
              {pending ? <SpinnerLoading /> : "Send Email"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
