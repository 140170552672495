import React from "react";
import { Quiz } from "../../components/Quiz/Quiz";

const CloseQuiz = () => {
  return (
    <div>
      <Quiz />
    </div>
  );
};

export default CloseQuiz;
