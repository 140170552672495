import React from "react";
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./router";
import NotFound from "./errorcomponent/404";
import { Toaster } from "sonner";

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {PublicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {PrivateRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    )
  );
  return (
    <React.Fragment>
      <Toaster richColors position="top-right" />
      <RouterProvider router={router} />
    </React.Fragment>
  );
};

export default App;
