import React, { useState } from "react";
import ModalContext from "../ModalContext/ModalContext";
import { VscReferences } from "react-icons/vsc";
import { IoClose } from "react-icons/io5";
import dustur from "../../assets/dustur.jpeg";
const References = () => {
  const [openReference, setOpenReference] = useState(false);
  return (
    <React.Fragment>
      <button
        onClick={() => {
          setOpenReference(true);
        }}
        className="flex items-center gap-3 bg-slate-700 rounded-md px-2 py-2 text-white"
      >
        <VscReferences />
        References
      </button>
      <ModalContext
        onRequestClose={() => {
          setOpenReference(false);
        }}
        modalIsOpen={openReference}
        iscollapse={true}
      >
        {" "}
        <div className="flex justify-between items-center bg-black text-white px-2 py-2 rounded-md">
          <div>Reference</div>{" "}
          <div className="flex gap-4 justify-between items-center">
            <button className="flex items-center gap-3">Collapse</button>

            <button
              className=""
              onClick={() => {
                setOpenReference(false);
              }}
            >
              <IoClose size={20} />
            </button>
          </div>
        </div>
        <img
          alt="dustur"
          className="w-full h-full object-cover aspect-auto"
          src={dustur}
        />
      </ModalContext>
    </React.Fragment>
  );
};

export default References;
