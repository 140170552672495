import React from "react";
import Modal from "react-modal";
const ExitDialog = ({ onClose, onOk, visible = false }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      padding: "20px",
      width: "400px",
    },
  };
  return (
    <>
      <Modal isOpen={visible} onRequestClose={onClose} style={customStyles}>
        <div>
          <h6
            className="
            text-start leading-6 
            font-weight-bold
            text-dark"
          >
            If you continue this operation, you'll finish your exam! And you
            will not have a second chance to do it! Are you sure to exit?
          </h6>
          <div className="d-flex justify-content-between mt-3">
            <button className="btn bg-black text-white" onClick={onClose}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={onOk}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExitDialog;
