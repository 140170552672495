import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  const hasToken = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user-verify"));
  if (!hasToken && user.verification === 0) {
    return <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  }

  if (hasToken && user?.verification === 1) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default Authmiddleware;
