import React from "react";
import Cards from "../../components/Cards";
import { CartProvider } from "react-use-cart";

const Home = () => {
  return (
    <div>
      <CartProvider>
        <Cards />
      </CartProvider>
    </div>
  );
};

export default Home;
