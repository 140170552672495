import * as React from "react";
import logo from "../../../assets/mainLogo.webp";
import { FaInstagram } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { FaFacebookSquare } from "react-icons/fa";
import axios from "axios";
import { toast } from "sonner";
import SpinnerLoading from "../../../components/LaodingSpinner";

const Footer = (props) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    axios
      .post("https://panel.digitalsatexam.az/api/subscribe", {
        email: email,
      })
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.errors.email[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="bg-slate-800 flex flex-col justify-center items-center px-5 py-12">
      <div className="flex w-full max-w-[1140px] flex-col items-stretch max-md:max-w-full">
        <div className="max-md:max-w-full">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
              <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-10">
                <div className="text-white text-2xl font-bold max-md:max-w-full">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-20 h-30  object-contain"
                  />
                </div>
                <div className="text-indigo-300 text-sm leading-6 mt-3 max-md:max-w-full">
                  <span className=" text-neutral-400">
                    Empower your success in the Digital SAT exam with our
                    comprehensive preparation resources. Unlock your potential,
                    master essential skills, and conquer the digital challenges
                    ahead. Your journey to academic excellence begins here. Get
                    ready to excel in the Digital SAT – where knowledge meets
                    innovation.
                  </span>
                  <span className="font-bold text-indigo-300">
                    <a
                      className="text-indigo-300"
                      href="https://satkurslari.az/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </a>
                  </span>
                </div>
                <div className="items-stretch flex w-full justify-between gap-5 mt-12 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
                  <div className="justify-between items-stretch flex gap-3">
                    <div className="justify-center items-center bg-neutral-700 flex aspect-square flex-col px-3 py-3.5 rounded-[30px]">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e3ec165-2886-452e-b53f-abb33f351d5a?apiKey=960bf456301745adb224fc22de8011ef&"
                        className="aspect-square object-contain object-center w-5 justify-center items-center overflow-hidden"
                      />
                    </div>
                    <div className="items-stretch self-center flex grow basis-[0%] flex-col my-auto">
                      <div className="text-neutral-400 text-xs">
                        Contact us at
                      </div>
                      <div className="text-white text-base font-medium">
                        <a
                          href="mailto:digitalsatexam@gmail.com"
                          target="_blank"
                        >
                          digitalsatexam@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
              <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-10">
                <div className="text-white text-lg font-bold capitalize max-md:max-w-full">
                  Newsletter
                </div>
                <div className="text-neutral-400 text-sm leading-6 mt-3 max-md:max-w-full">
                  Be the first one to know about discounts, offers and events.
                  Unsubscribe whenever you like.
                </div>
                <div className="items-center bg-neutral-700 flex w-full justify-between gap-3 mt-6 pl-6 pr-1.5 py-1.5 rounded-[30px] max-md:max-w-full max-md:flex-wrap max-md:pl-5">
                  <div className="flex items-stretch gap-2 my-auto">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c647e6ca-a26c-40b3-951b-048839c78c11?apiKey=960bf456301745adb224fc22de8011ef&"
                      className="aspect-square object-contain object-center w-4 justify-center items-center overflow-hidden shrink-0 max-w-full"
                    />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title="Please enter a valid email address."
                      className="text-neutral-400 bg-transparent px-3 outline-none py-2 text-center text-xs"
                    />
                  </div>
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="text-white text-center text-xs disabled:cursor-not-allowed font-bold justify-center items-center bg-indigo-500 self-stretch grow px-5 py-3 rounded-[30px]"
                  >
                    {loading ? <SpinnerLoading /> : "Subscribe"}
                  </button>
                </div>
                <div className="justify-start items-stretch flex gap-2 mt-8  max-md:max-w-full max-md:flex-wrap max-md:pl-5">
                  <div className="justify-center items-center bg-neutral-700 flex aspect-square flex-col p-3.5 rounded-[30px]">
                    <a
                      className="text-indigo-300"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/SATeducator"
                    >
                      <FaFacebookSquare size={24} />
                    </a>
                  </div>
                  <div className="justify-center items-center bg-neutral-700 flex aspect-square flex-col p-3.5 rounded-[30px]">
                    <a
                      className="text-indigo-300"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/sat_instructor_emin/"
                    >
                      <FaInstagram size={24} />
                    </a>
                  </div>
                  <div className="justify-center items-center bg-neutral-700 flex aspect-square flex-col p-3.5 rounded-[30px]">
                    <a
                      className="text-indigo-300"
                      target="_blank"
                      rel="noreferrer"
                      href="https://satkurslari.az"
                    >
                      <TfiWorld size={24} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-between items-stretch flex gap-0 mt-14 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
          <div className="items-stretch flex justify-between gap-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center">
            <a
              target="_blank"
              rel="noreferrer"
              href=" https://satkurslari.az"
              className="text-neutral-400 text-sm"
            >
              About us
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href=" https://satkurslari.az"
              className="text-neutral-400 text-sm"
            >
              Contact us
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href=" https://satkurslari.az"
              className="text-neutral-400 text-sm"
            >
              Term of use
            </a>
          </div>
          <div className="text-neutral-400 text-right text-sm grow shrink basis-auto max-md:max-w-full">
            © 2024, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
