import React from "react";
import ContentLoader from "react-content-loader";

const LoadingSkeleton = (props) => (
  <div className="container">
    <div className="row g-3">
      {[
        ...new Array(6).fill(6).map((x, i) => (
          <div className="col-xl-4" key={i}>
            <ContentLoader
              width="100%"
              height={400}
              viewBox="0 0 500 400"
              backgroundColor="#f0f0f0"
              foregroundColor="#dedede"
              {...props}
            >
              <rect x="43" y="304" rx="4" ry="4" width="271" height="7" />
              <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
              <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
            </ContentLoader>
          </div>
        )),
      ]}
    </div>
  </div>
);

export default LoadingSkeleton;
