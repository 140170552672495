import React from "react";
import { useNavigate } from "react-router-dom";
const ResultCard = ({
  date,
  moduleName,
  totalScore,
  mathScore,
  yourExam,
  readingScore,
  url,
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-slate-700 rounded-xl w-[300px]   shadow-md overflow-hidden md:max-w-2xl m-4">
      <div className="md:flex">
        <div className="w-full">
          <div className="uppercase p-3 tracking-wide text-sm text-white font-semibold text-end">
            {date}
          </div>
          <div className="bg-white rounded-lg w-full px-3 py-2">
            <div className="block mt-1 text-lg leading-tight font-medium text-black hover:underline text-center">
              {moduleName}
            </div>
            <div className="border-b pb-3">
              {" "}
              <p className="mt-2 text-gray-500 text-center">{yourExam}</p>
              <p className="text-4xl text-gray-800  text-center my-2">
                {totalScore}
              </p>
              {/* <p className="text-gray-600 text-center">400 to 1600</p> */}
            </div>
            <div className="mt-4 flex justify-between items-stretch">
              <div className="text-gray-500 text-sm">Your Math Score</div>
              <div className="flex flex-col justify-end items-center">
                {" "}
                <div className="text-2xl text-slate-600 ">{mathScore}</div>
                {/* <div className="text-gray-500 text-xs">200 to 800</div> */}
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center">
              <div className="text-gray-500 text-sm">
                Your Reading/Writing Score
              </div>
              <div className="flex flex-col justify-end items-center">
                <div className="text-2xl text-slate-600 ">{readingScore}</div>
                {/* <div className="text-gray-500 text-xs">200 to 800</div> */}
              </div>
            </div>
            <div className="mt-6">
              <button
                onClick={() => {
                  navigate(url);
                }}
                className="px-4 py-2 bg-slate-700 text-white text-base font-medium rounded-md w-full hover:bg-slate-800"
              >
                Score Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
