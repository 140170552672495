import React from "react";
import { IoTimeOutline } from "react-icons/io5";
import { GrScorecard } from "react-icons/gr";
import { BsUniversalAccess } from "react-icons/bs";
import { FaUnlockAlt } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

const StartExamView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [forms, setForms] = React.useState({
    warning: false,
    unlock: false,
  });

  const handleCheck = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.checked });
  };
  const HandleStartExam = () => {
    navigate(`/user-exams/${params.id}`);
  };

  return (
    <div className="min-h-screen w-full bg-gray-100 p-8">
      <div className="max-w-[500px] mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <h1 className="text-xl font-semibold text-gray-800">Exams</h1>
          <h2 className="text-lg font-semibold text-gray-700 mt-4">
            Practice Test
          </h2>
          <div className="space-y-3 mt-6">
            <div className="flex items-start space-x-4">
              <div>
                <p className="text-gray-900 text-md font-semibold  text-we flex items-center gap-2">
                  <div className="bg-gray-300 rounded-full w-8 h-8  flex items-center justify-center ">
                    {" "}
                    <IoTimeOutline size={20} />
                  </div>
                  Timing
                </p>
                <p className="text-gray-500 text-sm">
                  Practice tests are timed, but you can pause them. To continue
                  on another device, you have to start over. We delete
                  incomplete practice tests after 90 days.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <p className="text-gray-900 text-md font-semibold  text-we flex items-center gap-2">
                  <div className="bg-gray-300 rounded-full w-8 h-8  flex items-center justify-center ">
                    <GrScorecard size={20} />
                  </div>
                  Scores
                </p>
                <p className="text-gray-500 text-sm">
                  When you finish the practice test, go to My Practice to see
                  your scores and get personalized study tips.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <p className="text-gray-900 text-md font-semibold  text-we flex items-center gap-2">
                  <div className="bg-gray-300 rounded-full w-8 h-8  flex items-center justify-center ">
                    <BsUniversalAccess size={20} />
                  </div>
                  Assistive Technology
                </p>
                <p className="text-gray-500 text-sm">
                  If you use assistive technology, try it out on the practice
                  test so you know what to expect on test day.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <p className="text-gray-900 text-md font-semibold  text-we flex items-center gap-2">
                  <div className="bg-gray-300 rounded-full w-8 h-8  flex items-center justify-center ">
                    <FaUnlockAlt size={20} />
                  </div>
                  No Device Lock
                </p>
                <p className="text-gray-500 text-sm">
                  We don't lock your device during practice. On test day, you'll
                  be blocked from accessing other programs or apps.
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <p className="text-gray-900 text-md font-semibold  text-we flex items-center gap-2">
                  <div className="bg-gray-300 rounded-full w-8 h-8  flex items-center justify-center ">
                    {" "}
                    <IoWarning size={20} color="red" />
                  </div>
                  Warning!
                </p>
                <div className=" flex items-center gap-2 my-3">
                  <input
                    name="warning"
                    onChange={handleCheck}
                    type="checkbox"
                    className="mr-2 w-12 h-10 "
                  />
                  <p className="text-red-500 text-sm">
                    DON'T OPEN THE TEST USING YOUR MOBILE. IT'S YOUR
                    RESPONSIBILITY, IF YOU ENCOUNTER ANY PROBLEM! THIS TEST IS
                    DESIGNED TO OPERATE IN COMPUTER OR TABLET MODE.
                  </p>
                </div>
                <div className=" flex items-center gap-2 my-3">
                  <input
                    name="unlock"
                    onChange={handleCheck}
                    type="checkbox"
                    className="mr-2 w-10 h-10 "
                  />
                  <p className="text-red-500 text-sm">
                    If you leave the exam, or restart to browerser, you’ll lose
                    all your data! YOU WILL NOT HAVE A CHANCE TO DO THE EXAM
                    AGAIN! IF YOU AGREE, TICK THE BOX!
                  </p>
                </div>
              </div>
            </div>
            <button
              disabled={!forms.warning || !forms.unlock}
              onClick={HandleStartExam}
              className="w-full bg-slate-700 text-white rounded-md py-2 mt-2 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-800"
            >
              {" "}
              Start{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartExamView;
