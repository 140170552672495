import React from "react";

const LoadingSusponse = () => {
  return (
    <div className="flex justify-center items-center h-32">
      <div className="animate-spin rounded-full h-24 w-24 border-t-2 border-b-2 border-green-900"></div>
    </div>
  );
};

export default LoadingSusponse;
