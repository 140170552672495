import React from "react";
import ResultCard from "../ResultCard/ResultCard";
import { ensureArray } from "../../utils/ensureArray";
import resultImg from "../../assets/resultanime.json";
import Lottie from "lottie-react";
import { getResultAPi } from "../../utils/ApiHelper";

const Result = () => {
  const [testData, setTestData] = React.useState([]);
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    getResultAPi().then((res) => {
      setTestData(res.data?.exams);
    });
  }, [token]);

  return (
    <div className="py-12 bg-gray-50">
      {testData ? (
        <React.Fragment>
          <div className="max-w-9xl mx-auto">
            <div className="text-center mb-12">
              <h1 className="text-5xl font-extrabold sm:text-5xl">
                Your Score Report
              </h1>
            </div>
            <div className="flex md:justify-start md:space-x-8 md:px-14 flex-wrap">
              {testData &&
                ensureArray(testData)?.map((item, index) => (
                  <ResultCard
                    key={`exam_id_${index}`}
                    moduleName={item?.title}
                    totalScore={item?.exam_score}
                    date={item?.publish_date}
                    yourExam={testData?.course_detail?.exam_title}
                    examname={item?.title}
                    mathScore={item?.totalMath}
                    readingScore={item?.totalReading}
                    url={`/result/${item?.score_id}`}
                  />
                ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <Lottie
            style={{
              width: "100%",
              height: "200px",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            animationData={resultImg}
            loop={true}
          />
        </div>
      )}
    </div>
  );
};

export default Result;
