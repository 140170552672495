import React, { useEffect, useRef, useState } from "react";
import Desmos from "desmos";
import { FaCalculator, FaExpand, FaCompress } from "react-icons/fa";
import {
  COffcanvas,
  COffcanvasHeader,
  CCloseButton,
  COffcanvasBody,
} from "@coreui/react";
import { useDrag } from "../UseDrag/UseDrag";

const Calculate = () => {
  const [visible, setVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const draggableRef = useRef(null);
  const { position, handleMouseDown } = useDrag({
    ref: draggableRef,
  });
  const desmosContainer = useRef(null);

  useEffect(() => {
    const calculator = Desmos.GraphingCalculator(desmosContainer.current);
    return () => calculator.destroy();
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <React.Fragment>
      <button
        onClick={() => setVisible(true)}
        className="flex items-center gap-3 bg-slate-700 rounded-md px-2 py-2 text-white"
      >
        <FaCalculator />
        Calculator
      </button>
      <COffcanvas
        onMouseDown={handleMouseDown}
        backdrop="static"
        placement="end"
        visible={visible}
        className={`bg-white draggable ${
          isFullScreen
            ? "fixed inset-0 z-50"
            : "h-full max-h-[700px] max-w-[400px]"
        }`}
        onHide={() => setVisible(false)}
        ref={draggableRef}
        style={{
          top: isFullScreen ? 0 : position.y,
          left: isFullScreen ? 0 : position.x,
          width: isFullScreen ? "100vw" : "", // Ensure full width in full screen mode
          height: isFullScreen ? "100vh" : "", // Ensure full height in full screen mode
          transform: isFullScreen ? "none" : "", // Remove transform when full screen
        }}
      >
        <COffcanvasHeader>
          <div
            className="draggable-panel bg-black text-white flex justify-between w-full"
            onMouseDown={handleMouseDown}
          >
            Draggable Calculator
            <button onClick={toggleFullScreen} className="text-white">
              {isFullScreen ? <FaCompress /> : <FaExpand />}
            </button>
          </div>
          <CCloseButton
            className="text-reset"
            onClick={() => setVisible(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody className="draggable-content">
          <div
            ref={desmosContainer}
            className={isFullScreen ? "h-[calc(100vh-56px)]" : "h-full"}
            style={{ width: "100%" }}
          ></div>
        </COffcanvasBody>
      </COffcanvas>
    </React.Fragment>
  );
};

export default Calculate;
