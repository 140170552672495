import React, { useState, useEffect } from "react";

const BreakTimer = ({ timeLeft, setTimeLeft, onComplete }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((time) => {
        if (time <= 1) {
          clearInterval(interval);
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="flex w-full items-center justify-center h-screen bg-black text-white">
      <div className="flex max-w-5xl w-full justify-between items-center">
        <div className="flex flex-col items-center justify-center mb-8 border p-4 border-dashed border-white">
          <div className="mb-4 ">
            <span className="text-4xl font-bold">{formatTimeLeft()}</span>
          </div>
          <button
            onClick={onComplete}
            className="bg-yellow-500 text-black px-6 py-2 rounded font-bold"
          >
            Resume Testing
          </button>
        </div>
        <div>
          {" "}
          <div className="mb-8 max-w-2xl">
            <h1 className="text-2xl font-bold">Practice Test Break</h1>
            <p className="mt-4">
              You can resume this practice test as soon as you're ready to move
              on. On test day, you'll wait until the clock counts down. Read
              below to see how breaks work on test day.
            </p>
          </div>
          <div className="border-t border-gray-700 pt-4">
            <h2 className="font-bold">Take a Break Do Not Close Your Device</h2>
            <p className="mt-2">
              After the break, a Resume Testing Now button will appear and
              you'll start the next session.
            </p>
            <ol className="list-decimal ml-6 mt-4">
              <li>Do not disturb students who are still testing.</li>
              <li>Do not exit the app or close your laptop.</li>
              <li>
                Do not access phones, smartwatches, textbooks, notes, or the
                internet.
              </li>
              <li>Do not eat or drink near any testing device.</li>
              <li>
                Do not speak in the test room; outside the test room, do not
                discuss the exam with anyone.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakTimer;
