export function capitalizeFirstLetterOfEachWord(string) {
  if (!string) return "";
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const CheckStatus = (status) => {
  if (status === "Correct") {
    return "bg-green-100 text-green-500";
  } else if (status === "Incorrect") {
    return "bg-red-100 text-red-500";
  } else {
    return "bg-orange-100 text-orange-500";
  }
};
