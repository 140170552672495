import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import styled from "./login.module.scss";
import logo from "../../assets/Saly-14.png";
import clsx from "clsx";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import ReCAPTCHA from "react-google-recaptcha";
import { postLoginApi } from "../../utils/ApiHelper";
import { toast } from "sonner";
import SpinnerLoading from "../../components/LaodingSpinner";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(4).label("Password"),
    recapture: Yup.string().required().label("Recapture"),
  });
  const recaptchaRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recapture: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      formdata.append("recaptcha", values.recapture);

      setLoading(true);
      postLoginApi("auth/login", formdata)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoading(false);
            toast.success(res?.data?.message);
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem(
              "user-verify",
              JSON.stringify(res?.data?.user)
            );
            setTimeout(() => {
              Navigate("/home");
            }, 1000);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message || "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="flex items-center justify-center my-5 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="w-full h-full ">
              <h3 className={styled.sigin_in_p}>
                Welcome to Digital SAT Exams!{" "}
              </h3>
              <div className={styled.register__footer}>
                <div className={styled.register_text}>
                  <p>
                    {" "}
                    This website is designed to improve your SAT score! Good
                    luck!
                  </p>

                  <p className="underline">
                    <Link to="/register">You can Register here</Link>
                  </p>
                </div>
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <h1 className={styled.sign_in_rigth}>Sign in</h1>{" "}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="my-2 py-2">
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.email && formik.errors.email
                      ? " border-red-500 "
                      : " border-blue-800 "
                  )}
                  placeholder="Enter your email"
                  id="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                />
              </div>
              {formik.touched.email && (
                <span className="error mt-2 block">{formik.errors.email}</span>
              )}
              <div className={clsx("py-2 ", styled.eye__password)}>
                {showPassword ? (
                  <VscEye onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <VscEyeClosed
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.email && formik.errors.email
                      ? "border-red-500 rounded-md "
                      : "border-blue-800 rounded-md "
                  )}
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  placeholder="Enter your password"
                />
              </div>
              {formik.touched.password && (
                <span className="error block mb-2">
                  {formik.errors.password}
                </span>
              )}

              <ReCAPTCHA
                ref={recaptchaRef}
                className={styled.recapture}
                sitekey="6Lf8zSwgAAAAAI0unAzTzfF-v8nOJI_2BOSzZjfu"
                onChange={(value) => {
                  formik.setFieldValue("recapture", value);
                }}
              />
              {formik.touched.recapture && (
                <span className="error block mt-2">
                  {formik.errors.recapture}
                </span>
              )}

              <div>
                <p className="text-sm my-2"> If you don’t have an account,</p>
                <div className="flex underline items-center justify-between">
                  <Link
                    className="text-slate-800 underline text-sm font-semibold "
                    to="/register"
                  >
                    please create a new one!
                  </Link>
                  <Link
                    to="/forgot-password"
                    className="text-sm underline  text-slate-800 font-semibold"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <button
                disabled={loading}
                className="bg-slate-700 w-full disabled:cursor-not-allowed  rounded-md text-white py-3 my-2"
                type="button"
                onClick={formik.handleSubmit}
              >
                {loading ? <SpinnerLoading /> : "Login"}
              </button>
            </form>
            {/* <div className={styled.or_countinue}>
              <p>Or countinue with social media</p>

              <div className={styled.social_media}></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
