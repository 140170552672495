import React, { useEffect, useState } from "react";

export const OptionList = ({
  options = [],
  selectedAnswerIndex = null,
  onVariantSelected,
  answeredQuestions,
  setAnswerText,
  answerText = "",
  setStrikethrough,
  strikethrough,
}) => {
  const renderSelectedOptionBadge = (idx) => {
    if (selectedAnswerIndex === -1) {
      return null;
    }
  };
  const renderCorrectBadge = (idx) => {
    if (selectedAnswerIndex === -1) {
      return null;
    }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [undoneOptions, setUndoneOptions] = useState([]);
  const handleSelectOrUndo = (option) => {
    const isCurrentlySelected = selectedOption === option;
    const isCurrentlyUndone = undoneOptions.includes(option);
    if (isCurrentlyUndone) {
      setUndoneOptions(undoneOptions.filter((o) => o !== option));
      setSelectedOption(option);
    } else if (isCurrentlySelected) {
      setUndoneOptions([...undoneOptions, option]);
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };
  const isUndone = (option) => undoneOptions.includes(option);

  useEffect(() => {
    if (strikethrough === true) {
    }
  }, [strikethrough]);

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [options]);

  return (
    <React.Fragment>
      {" "}
      <div className="flex flex-col items-start space-y-1 w-full h-[500px]  overflow-auto ">
        {Object.keys(options).length > 3 ? (
          options.map((option, idx) => (
            <div
              key={idx}
              className={` relative flex gap-6 justify-between items-stretch w-full`}
            >
              {isUndone(option) && (
                <div
                  className="absolute
                transform -translate-y-1/2 transition-all duration-500 ease-in-out
                 top-50  left-[-5px] right-0 w-full max-w-[920px] h-[2px]  bg-black flex flex-col items-start space-y-1 "
                />
              )}

              <div
                key={idx}
                className={`relative flex items-center space-x-2 rounded-xl border-1 px-6 my-2 py-3 w-full cursor-pointer select-none ${
                  answeredQuestions.some(
                    (item) =>
                      item.answer === option?.variant &&
                      item.question_id === option?.question_id
                  )
                    ? "border-blue-500 "
                    : "border-ligth-gray-100"
                }`}
                onClick={() => {
                  onVariantSelected(option?.variant, idx);
                }}
              >
                <div
                  className={`w-[35px] h-[35px] mr-3 shrink-0 rounded-full   flex items-center justify-center ${
                    answeredQuestions.some(
                      (item) =>
                        item.answer === option?.variant &&
                        item.question_id === option?.question_id
                    )
                      ? "border-2 border-blue-500 bg-white"
                      : "bg-brand-white-smoke-100"
                  }`}
                >
                  {" "}
                  <span className="text-xl font-semibold flex items-center justify-center">
                    {option?.variant}
                  </span>
                  <button
                    type="button"
                    className={`w-[0] h-[0] rounded-full bg-white disabled:bg-gray-800 ${
                      answeredQuestions.some(
                        (item) => item.answer === option?.variant
                      )
                        ? "bg-white"
                        : "bg-brand-white-smoke-100"
                    }
          `}
                  />
                </div>
                <div
                  className="flex options_variant"
                  dangerouslySetInnerHTML={{
                    __html: option?.title,
                  }}
                ></div>

                {renderSelectedOptionBadge(idx)}
                {renderCorrectBadge(idx)}
              </div>
              {strikethrough && (
                <button
                  type="button"
                  onClick={() => {
                    handleSelectOrUndo(option);
                  }}
                >
                  {isUndone(option) ? "Redo" : "Undo"}
                </button>
              )}
            </div>
          ))
        ) : (
          <textarea
            onChange={(e) => setAnswerText(e.target.value)}
            value={answerText}
            maxLength={7}
            placeholder="Enter the Answer"
            className="w-full h-20 border border-gray-300 rounded-md p-2"
          ></textarea>
        )}
      </div>
    </React.Fragment>
  );
};
