import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import styled from "../Login/login.module.scss";
import logo from "../../assets/Saly-14.png";
import clsx from "clsx";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import SpinnerLoading from "../../components/LaodingSpinner";
import { Toaster, toast } from "sonner";
import { postRegisterApi } from "../../utils/ApiHelper";
import ReCAPTCHA from "react-google-recaptcha";
const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const recaptchaRef = React.useRef();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("Name"),
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(4).label("Password"),
    surname: Yup.string().required().label("Surname"),
    phone_number: Yup.string().required().label("Phone Number"),
    recapture: Yup.string().required().label("Recapture"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      surname: "",
      password: "",
      phone_number: "",
      recapture: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var formdata = new FormData();
      formdata.append("name_surname", values.name + " " + values.surname);
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      formdata.append("phone_number", values.phone_number);
      formdata.append("recaptcha", values.recapture);
      setLoading(true);
      postRegisterApi("auth/register", {
        data: formdata,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message);
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem(
              "user-verify",
              JSON.stringify(res?.data?.user)
            );
            setTimeout(() => {
              Navigate("/verify-registration");
            }, 1000);
          }
          if (res.status === 422) {
            toast.error(
              res?.data?.message ||
                "You have already created an account using this email! Go to the sign-in page!"
            );
          }
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message ||
              "You have already created an account using this email! Go to the sign-in page!"
          );
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  return (
    <div className="flex items-center justify-center my-5 ">
      <div className="container">
        {" "}
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="w-full h-full ">
              <h3 className={styled.sigin_in_p}>
                This website is designed to improve your SAT score! Good luck!{" "}
              </h3>
              <div className={styled.register__footer}>
                <div className={styled.register_text}>
                  <p> If you already have an account </p>
                  <p className="underline">
                    {" "}
                    <Link to="/">You can Login here !</Link>
                  </p>
                </div>
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <h1 className={styled.sign_in_rigth}>Sign Up</h1>{" "}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <div className="my-2 py-2">
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.email && formik.errors.name
                      ? " border-red-500 "
                      : " border-blue-800 "
                  )}
                  placeholder="Enter your name"
                  id="name"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                />
              </div>
              {formik.touched.name && (
                <span className="error">{formik.errors.name}</span>
              )}
              <div className="my-2 py-2">
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.surname && formik.errors.surname
                      ? " border-red-500 "
                      : " border-blue-800 "
                  )}
                  placeholder="Enter your surname"
                  id="surname"
                  type="text"
                  value={formik.values.surname}
                  onChange={formik.handleChange("surname")}
                />
              </div>
              {formik.touched.surname && (
                <span className="error">{formik.errors.surname}</span>
              )}
              <div className="my-2 py-2">
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.email && formik.errors.email
                      ? " border-red-500 "
                      : " border-blue-800 "
                  )}
                  placeholder="Enter your email"
                  id="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                />
              </div>
              {formik.touched.phone_number && (
                <span className="error">{formik.errors.phone_number}</span>
              )}
              <div className="my-2 py-2">
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.phone_number && formik.errors.phone_number
                      ? " border-red-500 "
                      : " border-blue-800 "
                  )}
                  placeholder="Enter your telephone number"
                  id="telephone"
                  type="text"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange("phone_number")}
                />
              </div>
              {formik.touched.phone_number && (
                <span className="error">{formik.errors.phone_number}</span>
              )}
              <div className={clsx("py-2 my-2", styled.eye__password)}>
                {showPassword ? (
                  <VscEye onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <VscEyeClosed
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
                <input
                  className={clsx(
                    styled.input_bg,
                    formik.touched.password && formik.errors.password
                      ? "border-red-500 rounded-md "
                      : "border-blue-800 rounded-md "
                  )}
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  placeholder="Enter your password"
                />
              </div>
              {formik.touched.password && (
                <span className="error">{formik.errors.password}</span>
              )}
              <ReCAPTCHA
                className="mt-2"
                ref={recaptchaRef}
                onChange={(value) => {
                  formik.setFieldValue("recapture", value);
                }}
                sitekey="6Lf8zSwgAAAAAI0unAzTzfF-v8nOJI_2BOSzZjfu"
              />
              {formik.touched.recapture && (
                <span className="error block mt-2">
                  {formik.errors.recapture}
                </span>
              )}
              <div>
                <p className="text-sm my-2"> If you already have an account </p>
                <Link
                  className="text-slate-800 text-sm font-semibold block mb-2 "
                  to="/"
                >
                  You can Login here !
                </Link>
                {/* <Link>Forget Password ?</Link> */}
              </div>
              <button
                disabled={loading}
                className={styled.btn_login}
                type="submit"
              >
                {loading ? <SpinnerLoading /> : "Register"}
              </button>
              {/* <div className={styled.or_countinue}>
                <p>Or countinue with social media</p>

                <div className={styled.social_media}>
                  <GoogleLoginBtn />
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
