// import React, { useEffect, useRef, useState } from "react";
// import { IoClose } from "react-icons/io5";
// import { MdOutlineZoomInMap } from "react-icons/md";
// import Modal from "react-modal";

// Modal.setAppElement("#root");

// const ModalContext = ({
//   modalIsOpen,
//   closeModal,
//   contentLabel,
//   id,
//   iscollapse,
//   children,
// }) => {
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [open, setOpen] = useState(true);
//   const customStyles = {
//     content: {
//       top: isZoomed ? "0" : "50%",
//       left: isZoomed ? "0" : "50%",
//       right: isZoomed ? "0" : "auto",
//       bottom: isZoomed ? "0" : "auto",
//       marginRight: isZoomed ? "0" : "-50%",
//       transform: isZoomed ? "" : "translate(-50%, -50%)",
//       width: isZoomed ? "98%" : "auto", // Full width when zoomed in
//       height: isZoomed ? "100%" : "auto", // Full height when zoomed in
//       overflow: isZoomed ? "auto" : "hidden",
//     },
//   };
//   const handleZoomClick = () => {
//     setIsZoomed(!isZoomed);
//   };
//   const modalContentRef = useRef();

//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (
//         modalContentRef.current &&
//         !modalContentRef.current.contains(event.target)
//       ) {
//         setOpen(false);
//       }
//     }
//     if (open) {
//       document.addEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [open, setOpen]);

//   return (
//     <Modal
//       isOpen={modalIsOpen}
//       onRequestClose={() => setOpen(false)}
//       style={customStyles}
//       contentLabel={contentLabel}
//       id={id}
//       ariaHideApp={false}
//     >
//       {" "}
//       {iscollapse ? (
//         <button
//           className="absolute top-6 z-20  right-36 mt-2 mr-2"
//           onClick={handleZoomClick}
//         >
//           <MdOutlineZoomInMap color="#fff" size={20} />
//         </button>
//       ) : null}
//       <div ref={modalContentRef}>{children}</div>
//     </Modal>
//   );
// };

// export default ModalContext;
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineZoomInMap } from "react-icons/md";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalContext = ({
  modalIsOpen,
  closeModal,
  contentLabel,
  iscollapse,
  children,
  maxWidth,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const customStyles = {
    content: {
      top: isZoomed ? "0" : "50%",
      left: isZoomed ? "0" : "50%",
      right: isZoomed ? "0" : "auto",
      bottom: isZoomed ? "0" : "auto",
      marginRight: isZoomed ? "0" : "-50%",
      transform: isZoomed ? "" : "translate(-50%, -50%)",
      width: "100%", // Adjust size here
      maxWidth: maxWidth, // Adjust size here
      overflow: "auto",
      zIndex: 1000,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  const handleZoomClick = () => {
    setIsZoomed(!isZoomed);
  };

  const modalRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       closeModal();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [closeModal]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={contentLabel}
      ariaHideApp={false}
    >
      <div ref={modalRef} className="relative">
        {" "}
        {iscollapse && (
          <button
            className="absolute top-6 right-36 mt-2 mr-2 z-20"
            onClick={handleZoomClick}
          >
            <MdOutlineZoomInMap color="#fff" size={20} />
          </button>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default ModalContext;
